import { Avatar } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function BookingDetalisListSideNavigation() {
  const navigateTo = useNavigate();

  function OnBookingDetalis() {
    navigateTo("/my-reservations");
  }

  function OncustomerDetalis() {
    navigateTo("/customerDetailsList");
  }
  function OnProfile() {
    navigateTo("/profile");
  }

  function handleReservationListEvent() {
    navigateTo("/myReservedBookings");
  }

  function handleRentalListEvent() {
    navigateTo("/myRentals");
  }

  function handleInvoiceListEvent() {
    navigateTo("/myInvoices");
  }

  return (
    <div className="text-[#020D53] text-[20px] px-10 py-0 shadow-grey !border-solid !border-x-2 !border-grey-500 mt-2 max-w-376 max-h-screen">
      <div>
        <ul className="flex items-center gap-4 mt-4">
          <li>
            <Avatar />
          </li>
          <li onClick={OnProfile} className="cursor-pointer">
            User Details
          </li>
        </ul>
      </div>
      <div className="mt-6">
        <ul className="flex flex-col gap-4 text-[18px] ">
          <a onClick={OnBookingDetalis} href="">
            <li className="flex items-center gap-4  hover:bg-[#EFEFEF] rounded-2xl w-200 p-2">
              <img
                className="h-12 "
                src="/assets/images/Booking details.svg"
                alt="Booking detalis icons"
              />
              Booking Details
            </li>
          </a>
          <a href="">
            <li
              onClick={OncustomerDetalis}
              className="flex items-center gap-4 hover:bg-[#EFEFEF] rounded-2xl p-2"
            >
              <img
                className="h-12"
                src="/assets/images/Customer details.svg"
                alt="Customer Detalis"
              />
              Customer Details
            </li>
          </a>
          <a href="">
            <li
              onClick={handleReservationListEvent}
              className="flex items-center gap-4 hover:bg-[#EFEFEF] rounded-2xl p-2"
            >
              <img
                className="h-12"
                src="/assets/images/List of reservation.svg"
                alt="List of reservation"
              />
              List of reservation
            </li>
          </a>
          <a href="">
            <li
              onClick={handleRentalListEvent}
              className="flex items-center gap-4 hover:bg-[#EFEFEF] rounded-2xl p-2"
            >
              <img
                className="h-12"
                src="/assets/images/List of rentals.svg"
                alt="List of rentals"
              />
              List of rentals
            </li>
          </a>
          <a href="">
            <li
              onClick={handleInvoiceListEvent}
              className="flex items-center gap-4 hover:bg-[#EFEFEF] rounded-2xl p-2 mb-20"
            >
              <img
                className="h-12"
                src="/assets/images/List of invoice.svg"
                alt="List of invoice"
              />
              List of invoice
            </li>
          </a>
        </ul>
      </div>
    </div>
  );
}

export default BookingDetalisListSideNavigation;
