import React, { useEffect, useState } from "react";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import { BookingApi } from "../../API/Apis/BookingApi";
import NotAuthorisedPage from "../notAuthorisedPage";
import ReservationCard from "../../components/Layouts/ReservationsCard/ReservationsCards";

export default function MyReservedBookings(props) {
  const [showReservedBooking, setShowReservedBooking] = useState(false);
  const [openTab, setOpenTab] = useState(1);
  useEffect(() => {
    BookingApi.getAppSetting().then((e) => {
      setShowReservedBooking(e.reservedArea);
    });
  }, [showReservedBooking]);
  return (
    <div>
      <div className="flex">
        <BookingDetalisListSideNavigation />
        {showReservedBooking ? (
          <div>
            <div className="container  mx-4">
              <div className="flex flex-col   ">
                <div className="flex justify-between space-x-2 mb-8  ">
                  <div>
                    <h1 className="font-medium text-[36px] text-[#232323]">
                      Reservation List
                    </h1>
                    <h4 className="text-[#838181] font-normal text-[20px]">
                      Here is your next reserve bookings.
                    </h4>
                  </div>
                </div>

                <ul className="flex space-x-2 mb-12 absolute right-0">
                  <li>
                    <a
                      href="#"
                      onClick={() => setOpenTab(1)}
                      variant="active"
                      className={` ${
                        openTab === 1 ? "bg-[#020D53] text-[#fff]" : ""
                      } ${"inline-block px-4 py-2 w-40  h-70 text-gray-600  text-center rounded shadow"}`}
                    >
                      Confirm
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => setOpenTab(2)}
                      className={` ${
                        openTab === 2 ? "bg-[#020D53]  text-[#fff]" : ""
                      } inline-block px-4 py-2 w-40 h-70 text-gray-600 text-center rounded shadow`}
                    >
                      Pending
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => setOpenTab(3)}
                      className={` ${
                        openTab === 3 ? "bg-[#020D53]  text-[#fff]" : ""
                      } inline-block px-4 py-2 w-40  h-70  text-gray-600 text-center  rounded shadow`}
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
                <div className={openTab === 1 ? "block" : "hidden"}>
                  <ReservationCard
                    callFrom={"reservedBookingTab1"}
                  ></ReservationCard>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"}>
                  <ReservationCard
                    callFrom={"reservedBookingTab2"}
                  ></ReservationCard>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"}>
                  <ReservationCard
                    callFrom={"reservedBookingTab3"}
                  ></ReservationCard>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <NotAuthorisedPage />
        )}
      </div>
    </div>
  );
}
