import React, { useEffect, useState } from "react";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import { BookingApi } from "../../API/Apis/BookingApi";
import NotAuthorisedPage from "../notAuthorisedPage";
import InvoiceCard from "./InvoiceCard";

export default function MyInvoices(props) {
  const [showInvoices, setShowInvoices] = useState(false);
  useEffect(() => {
    BookingApi.getAppSetting().then((e) => {
      setShowInvoices(e.reservedArea);
    });
  }, [showInvoices]);
  return (
    <div>
      <div className="flex">
        <BookingDetalisListSideNavigation />
        {showInvoices ? (
          <div>
            <div className="container mx-4">
              <div className="flex flex-col">
                <div className="flex justify-between space-x-2 mb-8">
                  <div>
                    <h1 className="font-medium text-[36px] text-[#232323]">
                      Invoice List
                    </h1>
                    <h4 className="text-[#838181] font-normal text-[20px]">
                      View all your Invoices here.
                    </h4>
                  </div>
                </div>
                <div className={"block"}>
                  <InvoiceCard></InvoiceCard>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <NotAuthorisedPage />
        )}
      </div>
    </div>
  );
}
