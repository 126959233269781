import InfiniteScroll from "react-infinite-scroll-component";
import { BookingVehicleInfoCard } from "../BookingVehicleInfoCard/BookingVehicleInfoCard";
import React, { useEffect, useRef, useState } from "react";
import { Vehicle } from "../../../@types/DTO/Vehicles";
import { ShowVehiclesProps } from "./ShowVehiclesProps";
import { Box, CircularProgress } from "@mui/material";
import { start } from "repl";
import "./Scrollbar.css";
import BookingGetters from "../../../store/onlineBooking/getters";

export function ShowVehicles({
  vehiclesFilter = {
    status: {},
    category: {},
    type: {},
    TotalSeats: [],
    MacroGroup: {},
    Transmission: {},
  },
  elementsToShowInOnePage = 3,
  allVehicles = [],
  onVehicleSelect = () => {},
}: ShowVehiclesProps): JSX.Element {
  const [availableVehicles, setAvailableVehicles] = useState<Vehicle[]>([]);
  // const [unAvailableVehicles, setUnAvailableVehicles] = useState<Vehicle[]>([]);
  // const [onRequestVehicle, setOnRequestVehicles] = useState<Vehicle[]>([]);
  const [availableVehiclesPageNumber, setAvailableVehiclesPageNumber] =
    useState<number>(1);
  // const [unAvailableVehiclesPageNumber, setUnAvailableVehiclesPageNumber] =
  //   useState<number>(0);
  // const [onRequestVehiclesPageNumber, setOnRequestVehiclesPageNumber] =
  //   useState<number>(0);
  const [showingVehicles, setShowingVehicles] = useState<Vehicle[]>([]);
  const [isLoadingMoreVehicles, setIsLoadingMoreVehicles] =
    useState<boolean>(false);
  const [haveMoreVehiclesToShow, setHaveMoreVehiclesToShow] =
    useState<boolean>(false);
  const infiteScrollContainer = useRef(null);

  function addAvailableVehicles() {
    const startIndex = availableVehiclesPageNumber * elementsToShowInOnePage;
    const endIndex = startIndex + elementsToShowInOnePage;
    // console.log(showingVehicles);

    const result: Vehicle[] = availableVehicles.slice(0, endIndex);

    return result;
  }
  // function addUnAvailableVehicles() {
  //   const startIndex = unAvailableVehiclesPageNumber * elementsToShowInOnePage;
  //   const endIndex = startIndex + elementsToShowInOnePage;
  //   const result: Vehicle[] = unAvailableVehicles.slice(startIndex, endIndex);
  //   if (result.length) {
  //     setShowingVehicles((e) => {
  //       return [...e, ...result];
  //     });
  //     setUnAvailableVehiclesPageNumber((prev) => prev + 1);
  //   }
  //   return result;
  // }

  function requestParentResize() {
    const iframeHeight = document.body.scrollHeight;
    window.parent.postMessage(
      {
        event_id: "iframeHeight",
        data: {
          iframeHeight: iframeHeight,
        },
      },
      "*"
    );
  }
  const AppSettings = BookingGetters.getAppSettings();
  useEffect(() => {
    if (AppSettings?.showInIframe) {
      requestParentResize();
    }
  }, [showingVehicles]);
  /*  function addOnRequestVehicles() {
    const startIndex = onRequestVehiclesPageNumber * elementsToShowInOnePage;
    const endIndex = startIndex + elementsToShowInOnePage;
    const result: Vehicle[] = onRequestVehicle.slice(startIndex, endIndex);
    if (result.length) {
      setShowingVehicles((e) => {
        return [...e, ...result];
      });
      setOnRequestVehiclesPageNumber((prev) => prev + 1);
    }
    return result;
  }*/
  function loadMoreVehicles() {
    let result: Vehicle[] = [];
    setIsLoadingMoreVehicles(true);
    result = [...addAvailableVehicles()];
    if (result.length <= availableVehicles.length) {
      setShowingVehicles([...result]);
      setAvailableVehiclesPageNumber((prev) => prev + 1);
    }
    // if (
    //   vehiclesFilter.status["onrequest"] &&
    //   result.length < elementsToShowInOnePage
    // ) {
    //   result = [...result, ...addOnRequestVehicles()];
    // }
    // if (
    //   vehiclesFilter.status["unavailable"] &&
    //   result.length < elementsToShowInOnePage
    // ) {
    //   result = [...result, ...addUnAvailableVehicles()];
    // }
    // console.log(result);

    setHaveMoreVehiclesToShow(true);
    setIsLoadingMoreVehicles(false);
  }
  function segregateData() {
    setAvailableVehicles(
      (
        allVehicles.filter(
          (e) =>
            e.Status.toLowerCase() === "available" ||
            e.Status.toLowerCase() === "onrequest"
        ) || []
      )
        .filter((vehicle) => {
          const length = Object.keys(vehiclesFilter.type).length;
          if (length < 1) {
            return true;
          }
          const fuelType =
            vehicle.Vehicle?.fuelType?.description?.toLowerCase() || "";
          return fuelType in vehiclesFilter.type;
        })
        .filter((e) => {
          if (!vehiclesFilter.TotalSeats.length) {
            return true;
          }
          return vehiclesFilter.TotalSeats.filter(
            (v) => v.toString() == (e.Vehicle?.seats.toString() || "0")
          ).length;
        })
        .filter((e) => {
          if (!Object.keys(vehiclesFilter.Transmission).length) {
            return true;
          }
          const selectedTransmission = vehiclesFilter.Transmission;
          const transmissionDescription =
            e.Vehicle?.transmission?.description?.toLowerCase() || "";
          return Object.keys(selectedTransmission)
            .map((e) => e.toLowerCase())
            .includes(transmissionDescription.toLocaleLowerCase());
        })
        .sort((a, b) => {
          const priceA = a.TotalCharge?.RateTotalAmount || 0;
          const priceB = b.TotalCharge?.RateTotalAmount || 0;

          if (priceA === 0) return 1;
          if (priceB === 0) return -1;

          if (
            a.Status.toLowerCase() === "onrequest" &&
            b.Status.toLowerCase() !== "onrequest"
          ) {
            return 1;
          } else if (
            a.Status.toLowerCase() !== "onrequest" &&
            b.Status.toLowerCase() === "onrequest"
          ) {
            return -1;
          }
          return priceA - priceB;
        })
    );

    // setUnAvailableVehicles(
    //   (
    //     allVehicles.filter((e) => e.Status.toLowerCase() === "unavailable") ||
    //     []
    //   ).filter((e) => {
    //     if (Object.keys(vehiclesFilter.type).length < 1) {
    //       return e;
    //     }
    //     return !!vehiclesFilter.type[
    //       e.Vehicle?.fuelType?.description?.toLowerCase() || ""
    //     ];
    //   })
    // );
    // setOnRequestVehicles(
    //   (
    //     allVehicles.filter((e) => e.Status.toLowerCase() === "onrequest") || []
    //   ).filter((e) => {
    //     if (Object.keys(vehiclesFilter.type).length < 1) {
    //       return e;
    //     }
    //     return !!vehiclesFilter.type[
    //       e.Vehicle?.fuelType?.description?.toLowerCase() || ""
    //     ];
    //   })
    // );
  }
  useEffect(() => {
    segregateData();
    RefreshData();
  }, [allVehicles]);
  useEffect(() => {
    segregateData();
    RefreshData();
  }, [vehiclesFilter]);
  useEffect(() => {
    RefreshData();
  }, [availableVehicles, vehiclesFilter]);

  function RefreshData() {
    setShowingVehicles([]);
    setAvailableVehiclesPageNumber(1);
    loadMoreVehicles();
  }
  function handleScroll(e: any) {
    console.log(e.target.clientHeight, e.target.scrollTop, e.target);
  }

  return (
    <>
      {/*<p className={"text-black"}>{JSON.stringify(isLoadingMoreVehicles)}</p>*/}
      {/* <p className={"text-black"}>
        {JSON.stringify(haveMoreVehiclesToShow)}
        {showingVehicles.length}
      </p> */}
      {/* <InfiniteScroll
        scrollableTarget={infiteScrollContainer}
        style={{ height: "100%" }}
        dataLength={showingVehicles.length}
        next={() => {
          loadMoreVehicles();
        }}
        hasMore={haveMoreVehiclesToShow}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      > */}
      {!isLoadingMoreVehicles && (
        <div
          className="font-bold text-center text-black h-full flex flex-col gap-4 w-full"
          ref={infiteScrollContainer}
        >
          {showingVehicles.length
            ? showingVehicles.map((el: Vehicle, index) => {
                return (
                  <div
                    key={index}
                    className="border-solid border-2 border-gray-300 rounded cursor-pointer hover:border-blue-500 hover:border-3"
                  >
                    {/* {el.Vehicle?.seats || 0} */}
                    <BookingVehicleInfoCard
                      extras={
                        el.optionals
                          ? el.optionals?.map((e) => {
                              return {
                                ...e,
                                iconUrl: e.Equipment.optionalImage || "#",
                                maxAllowed: e.Equipment.isMultipliable ? 20 : 1,
                                optionName: e.Equipment?.EquipType || "",
                                pricePerDayInUSD: (
                                  e.Charge.Amount || 0
                                ).toString(),
                                details: e.Equipment?.Description || "",
                              };
                            })
                          : []
                      }
                      discount={el.Vehicle?.payNowDis || 0}
                      status={el.Status}
                      modelYear=""
                      vehicleGroup={el.Vehicle?.groupPic?.nationalCode || ""}
                      priceDetails={{
                        payNow: (
                          el.TotalCharge?.RateTotalAmount || 0
                        ).toString(),
                        payLater: (
                          el.TotalCharge?.RateTotalAmount || 0
                        ).toString(),
                      }}
                      // specifications={[
                      //   {
                      //     iconUrl: "#",
                      //     name: "fuel type",
                      //     value: el.Vehicle?.fuelType?.description || "N/A",
                      //   },
                      //   {
                      //     iconUrl: "#",
                      //     name: "transmission type",
                      //     value:
                      //       el.Vehicle?.transmission?.description || "N/A",
                      //   },
                      //   {
                      //     iconUrl: "#",
                      //     name: "seat number",
                      //     value: (el.Vehicle?.seats || "N/A").toString(),
                      //   },
                      //   {
                      //     iconUrl: "#",
                      //     name: "seat number",
                      //     value: (el.Vehicle?.seats || "N/A").toString(),
                      //   },
                      // ]}
                      specifications={
                        //merge sorting done
                        el.Vehicle?.vehicleParameter
                          .sort((e1, e2) => {
                            return e1["position:"] - e2["position:"];
                          })
                          .map((e) => {
                            return {
                              iconUrl: e["fileUrl"],
                              name: e["name"],
                              value: e["name"],
                            };
                          }) || []
                      }
                      topFeature={""}
                      vehicleImages={[
                        el.Vehicle?.vehicleGroupPic || "",
                        ...(el.Vehicle?.vehicelExtraImage || []).filter((e) => {
                          return !!e;
                        }),
                      ]}
                      vehicleName={el.Vehicle?.VehMakeModel?.Name || ""}
                      payLater={"Pay Later"}
                      disclaimer={
                        "Le opzioni di questo veicolo da consegnare possono variare"
                      }
                      payNow={"Pay Now"}
                      selectExtra={"Choose Insurance"}
                      closeExtra={"Chiudi extra"}
                      viewAllSpecifications={"Visualizza tutte le specifiche"}
                      moveToPayment={(e) => {
                        //  console.log(e, el);
                        onVehicleSelect({
                          vehicleDetails: el,
                          paymentDetails: e,
                        });
                      }}
                    />
                  </div>
                );
              })
            : "No Vehicles Available"}
        </div>
      )}
      {isLoadingMoreVehicles && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {/* </InfiniteScroll> */}
    </>
  );
}
