import { WebCheckInFormFields } from "../../components/Layouts/WebCheckInForms/FormOptions";

export const CustomerDetailFormField = {
  firstName: WebCheckInFormFields.firstName,
  middleName: WebCheckInFormFields.middleName,
  lastName: WebCheckInFormFields.lastName,
  email: {
    ...WebCheckInFormFields.email,
    type: "email",
    required: true,
  },
  mobileNumber: { ...WebCheckInFormFields.mobileNumber, required: true },
  country: WebCheckInFormFields.country,
  city: WebCheckInFormFields.city,
  state: WebCheckInFormFields.state,
  zip: WebCheckInFormFields.zip,
  streetName: {
    ...WebCheckInFormFields.streetName,
    variant: "standard",
  },
  n: WebCheckInFormFields.n,
  birthNation: {
    ...WebCheckInFormFields.birthNation,
    type: "select",
    variant: "standard",
  },
  birthDate: {
    ...WebCheckInFormFields.birthDate,
    variant: "standard",
    required: true,
  },
  birthPlace: WebCheckInFormFields.birthPlace,
  birthProv: WebCheckInFormFields.birthProv,
  gender: { ...WebCheckInFormFields.gender, required: true },
  ssn: { ...WebCheckInFormFields.ssn, required: true },
};
