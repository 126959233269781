import PropTypes from "prop-types";
import React from "react";

VehicleSpecification.propTypes = {
  name: PropTypes.string.isRequired,
  iconUrl: PropTypes.string,
  value: PropTypes.string.isRequired,
};

function VehicleSpecification({ name, iconUrl, value }) {
  const specificationBorder = "border-2 border-solid border-gray-100";
  return (
    <div
      className={
        "w-full h-full grow  flex flex-col items-center justify-center gap-4"
      }
    >
      <img src={iconUrl} alt={name} className="h-8 w-8" />
      <span className="uppercase text-gray-600 text-center">{name}</span>
    </div>
  );
}
export { VehicleSpecification };
