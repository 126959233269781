import React, { memo, useEffect, useState } from "react";
import BookingApi from "../../../API/Apis/BookingApi";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";

function ReservationCard(props) {
  const callingFrom = props.callFrom;
  const [noOfBooking, setNoOfBooking] = useState<number>(0);
  const [reservationList, setReservationList] = useState([]);
  const [rentalCount, setRentalCount] = useState(0);
  const [rentalList, setRentalList] = useState([]);
  const [loader, setLoader] = useState(true);

  if (
    callingFrom == "reservedBookingTab1" ||
    callingFrom == "reservedBookingTab2" ||
    callingFrom == "reservedBookingTab3"
  ) {
    useEffect(() => {
      setLoader(true);
      const id: any = localStorage.getItem("userID");
      BookingApi.getBookingDetailsbyUserId(id).then((e) => {
        setNoOfBooking(e.data.total);
        e.data.reservationList.forEach((e) => {});
        setReservationList(e.data.reservationList);
        setLoader(false);
      });
    }, []);
  } else if (callingFrom == "rentals") {
    useEffect(() => {
      setLoader(true);
      const id: any = localStorage.getItem("userID");
      BookingApi.getRentalDetailsbyUserId(id).then((e) => {
        setRentalCount(e.data.total);
        setRentalList(e.data.rentalList);
        setLoader(false);
      });
    }, []);
  } else if (callingFrom == "booking") {
    // useEffect(() => {
    //   setLoader(true);
    //   const id: any = localStorage.getItem("userID");
    //   BookingApi.getLastBookingDetail(id).then((e) => {
    //     setReservationList(e.data.data);
    //     setLoader(false);
    //   });
    // }, []);
  }

  const downloadInvoice = (invoiceId) => {
    if (invoiceId == "No Invoice Found") {
      alert("No Invoice Found");
    } else {
      BookingApi.downloadInvoice(invoiceId).then((e) => {
        if (e.data.status == "error") {
          alert(e.data.errorMessage);
        } else {
          const url = e.data;
          window.open(url, "_blank");
        }
      });
    }
  };

  return (
    <>
      {loader ? (
        <Typography className="!text-center  !text-3xl !flex !justify-center  ">
          Loading...
        </Typography>
      ) : (
        <TableContainer>
          <Table
            className="!border-2  rounded-2xl"
            aria-label="simple table"
            stickyHeader
          >
            <TableHead className="!bg-[#020D53] text-[24px] ">
              <TableRow className="!bg-[#020D53] rounded-2xl">
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                  Customer Details
                </TableCell>
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px] ">
                  Pick up location
                </TableCell>
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                  Drop off location
                </TableCell>
                {callingFrom == "rentals" && (
                  <>
                    <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                      Start Date
                    </TableCell>
                    <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                      End Date
                    </TableCell>
                  </>
                )}
                {callingFrom != "rentals" && (
                  <>
                    <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                      Reservation Date
                    </TableCell>
                  </>
                )}
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                  Vehicle details
                </TableCell>
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                  Invoice
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {callingFrom == "rentals" && (
                <>
                  {rentalList.map((e: any, i) => (
                    <TableRow key={i}>
                      <TableCell
                        className="!text-[14px] text-[#333]"
                        component="th"
                        scope="e"
                      >
                        <ul>
                          <li className="!text-[#333]">
                            {e.clientiByIdCliente.client || ""}
                          </li>
                          <li className="text-[#838181] text-[12px]">
                            {e.clientiByIdCliente.email || ""}
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell
                        className="!text-[14px] text-[#333]"
                        component="th"
                        scope="e"
                      >
                        <ul>
                          <li className="!text-[#333]">
                            {e.locationPickUpExcepted.location || ""}
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell
                        className="!text-[14px] text-[#333]"
                        component="th"
                        scope="e"
                      >
                        <ul>
                          <li className="!text-[#333]">
                            {e.locationReturnExpected.location || ""}
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell
                        className="!text-[14px] text-[#333]"
                        component="th"
                        scope="e"
                      >
                        <ul>
                          {`${new Date(e.data).getDate()}-0${
                            new Date(e.data).getMonth() + 1
                          }-${new Date(e.data).getFullYear()}`}{" "}
                        </ul>
                      </TableCell>
                      <TableCell
                        className="!text-[14px] text-[#333]"
                        component="th"
                        scope="e"
                      >
                        <ul>
                          {`${new Date(e.endDate).getDate()}-0${
                            new Date(e.endDate).getMonth() + 1
                          }-${new Date(e.endDate).getFullYear()}`}{" "}
                        </ul>
                      </TableCell>
                      <TableCell
                        className="!text-[14px] text-[#333]"
                        component="th"
                        scope="e"
                      >
                        <ul>
                          <li className="!text-[#333]">
                            {e.assignedGroup.plateNumber || ""}
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell>
                        {e.invoices.length == 0 && (
                          <>
                            <button
                              className="flex  text-[14px] bg-[#FF0000] gap-2  px-4 py-2  h-70 text-[#fff] rounded shadow "
                              onClick={() =>
                                downloadInvoice("No Invoice Found")
                              }
                            >
                              Invoice{" "}
                              <img
                                className="h-6"
                                src="/assets/images/Invoice.svg"
                                alt="Invoice Icon"
                              />
                            </button>
                          </>
                        )}
                        {e.invoices.length > 0 && (
                          <>
                            <button
                              className="flex  text-[14px] bg-[#FF0000] gap-2  px-4 py-2  h-70 text-[#fff] rounded shadow "
                              onClick={() => downloadInvoice(e.invoices[0].id)}
                            >
                              Invoice{" "}
                              <img
                                className="h-6"
                                src="/assets/images/Invoice.svg"
                                alt="Invoice Icon"
                              />
                            </button>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
              {callingFrom == "booking" && (
                <>
                  {reservationList.map((e: any, i) => (
                    <TableRow key={i}>
                      <TableCell
                        className="!text-[14px] text-[#333]"
                        component="th"
                        scope="e"
                      >
                        <ul>
                          <li className="!text-[#333]">{e.dbid || ""}</li>
                        </ul>
                      </TableCell>
                      <TableCell className="!text-[14px]">
                        <ul>
                          <li>{e.dbid || ""}</li>
                          <li className="text-[#838181] text-[12px] mx-12">
                            {e.dbid || "no data"}
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell className="!text-[14px]">
                        <ul>
                          <li>{e.dbid || "NO Data"}</li>
                          <li className="text-[#838181] text-[12px] mx-12">
                            {e.dbid || "No Data"}
                          </li>
                        </ul>
                      </TableCell>

                      <TableCell className="!text-[14px]"></TableCell>
                      <TableCell className="!text-[14px]">
                        {e.dbid || "no data"}
                      </TableCell>
                      <TableCell>
                        {/*{e.invoices.length == 0 && (*/}
                        {/*  <>*/}
                        {/*    <button*/}
                        {/*      className="flex  text-[14px] bg-[#FF0000] gap-2  px-4 py-2  h-70 text-[#fff] rounded shadow "*/}
                        {/*      onClick={() =>*/}
                        {/*        downloadInvoice("No Invoice Found")*/}
                        {/*      }*/}
                        {/*    >*/}
                        {/*      Invoice{" "}*/}
                        {/*      <img*/}
                        {/*        className="h-6"*/}
                        {/*        src="/assets/images/Invoice.svg"*/}
                        {/*        alt="Invoice Icon"*/}
                        {/*      />*/}
                        {/*    </button>*/}
                        {/*  </>*/}
                        {/*)}*/}
                        {/*{e.invoices.length > 0 && (*/}
                        {/*  <>*/}
                        {/*    <button*/}
                        {/*      className="flex  text-[14px] bg-[#FF0000] gap-2  px-4 py-2  h-70 text-[#fff] rounded shadow "*/}
                        {/*      onClick={() => downloadInvoice(e.invoices[0].id)}*/}
                        {/*    >*/}
                        {/*      Invoice{" "}*/}
                        {/*      <img*/}
                        {/*        className="h-6"*/}
                        {/*        src="/assets/images/Invoice.svg"*/}
                        {/*        alt="Invoice Icon"*/}
                        {/*      />*/}
                        {/*    </button>*/}
                        {/*  </>*/}
                        {/*)}*/}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
              {callingFrom == "reservedBookingTab1" && (
                <>
                  {reservationList.map(
                    (e: any, i) =>
                      e.isConfirmed === true && (
                        <TableRow key={i}>
                          <TableCell
                            className="!text-[14px] text-[#333]"
                            component="th"
                            scope="e"
                          >
                            <ul>
                              <li className="!text-[#333]">
                                {e.clientiByIdCliente.description
                                  .split("/")[0]
                                  .split("-")
                                  .reverse("")
                                  .join(" ") || "No Data"}
                              </li>
                              <li className="text-[#838181] text-[12px]">
                                {e.email || "no data"}
                              </li>
                            </ul>
                          </TableCell>
                          <TableCell className="!text-[14px]">
                            <ul>
                              <li>
                                {e.locationPickupExcepted.description.split(
                                  "-"
                                )[0] || "NO Data"}
                              </li>
                              <li className="text-[#838181] text-[12px] mx-12">
                                {e.startDate.slice(11, 19) || "no data"}
                              </li>
                            </ul>
                          </TableCell>
                          <TableCell className="!text-[14px]">
                            <ul>
                              <li>
                                {e.locationReturnExcepted.description.split(
                                  "-"
                                )[0] || "NO Data"}
                              </li>
                              <li className="text-[#838181] text-[12px] mx-12">
                                {e.endDate.slice(11, 19) || "No Data"}
                              </li>
                            </ul>
                          </TableCell>

                          <TableCell className="!text-[14px]">
                            {`${new Date(e.startDate).getDate()}-0${
                              new Date(e.startDate).getMonth() + 1
                            }-${new Date(e.startDate).getFullYear()}`}{" "}
                            -{" "}
                            {`${new Date(e.endDate).getDate()}-0${
                              new Date(e.endDate).getMonth() + 1
                            }-${new Date(e.endDate).getFullYear()}`}
                          </TableCell>
                          <TableCell className="!text-[14px]">
                            {e.assignedGroup.description || ""}
                          </TableCell>
                          <TableCell>
                            {e.invoices.length == 0 && (
                              <>
                                <button
                                  className="flex  text-[14px] bg-[#FF0000] gap-2  px-4 py-2  h-70 text-[#fff] rounded shadow "
                                  onClick={() =>
                                    downloadInvoice("No Invoice Found")
                                  }
                                >
                                  Invoice{" "}
                                  <img
                                    className="h-6"
                                    src="/assets/images/Invoice.svg"
                                    alt="Invoice Icon"
                                  />
                                </button>
                              </>
                            )}
                            {e.invoices.length > 0 && (
                              <>
                                <button
                                  className="flex  text-[14px] bg-[#FF0000] gap-2  px-4 py-2  h-70 text-[#fff] rounded shadow "
                                  onClick={() =>
                                    downloadInvoice(e.invoices[0].id)
                                  }
                                >
                                  Invoice{" "}
                                  <img
                                    className="h-6"
                                    src="/assets/images/Invoice.svg"
                                    alt="Invoice Icon"
                                  />
                                </button>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </>
              )}
              {callingFrom == "reservedBookingTab2" && (
                <>
                  {reservationList.map(
                    (e: any, i) =>
                      e.isConfirmed === false &&
                      e.isCancel === false && (
                        <TableRow key={i}>
                          <TableCell
                            className="!text-[14px] text-[#333]"
                            component="th"
                            scope="e"
                          >
                            <ul>
                              <li className="!text-[#333]">
                                {e.clientiByIdCliente.description
                                  .split("/")[0]
                                  .split("-")
                                  .reverse("")
                                  .join(" ") || "No Data"}
                              </li>
                              <li className="text-[#838181] text-[12px]">
                                {e.email || "no data"}
                              </li>
                            </ul>
                          </TableCell>
                          <TableCell className="!text-[14px]">
                            <ul>
                              <li>
                                {e.locationPickupExcepted.description.split(
                                  "-"
                                )[0] || "NO Data"}
                              </li>
                              <li className="text-[#838181] text-[12px] mx-12">
                                {e.startDate.slice(11, 19) || "no data"}
                              </li>
                            </ul>
                          </TableCell>
                          <TableCell className="!text-[14px]">
                            <ul>
                              <li>
                                {e.locationReturnExcepted.description.split(
                                  "-"
                                )[0] || "NO Data"}
                              </li>
                              <li className="text-[#838181] text-[12px] mx-12">
                                {e.endDate.slice(11, 19) || "No Data"}
                              </li>
                            </ul>
                          </TableCell>

                          <TableCell className="!text-[14px]">
                            {`${new Date(e.startDate).getDate()}-0${
                              new Date(e.startDate).getMonth() + 1
                            }-${new Date(e.startDate).getFullYear()}`}{" "}
                            -{" "}
                            {`${new Date(e.endDate).getDate()}-0${
                              new Date(e.endDate).getMonth() + 1
                            }-${new Date(e.endDate).getFullYear()}`}
                          </TableCell>
                          <TableCell className="!text-[14px]">
                            {e.assignedGroup.description || ""}
                          </TableCell>
                          <TableCell>
                            {e.invoices.length == 0 && (
                              <>
                                <button
                                  className="flex  text-[14px] bg-[#FF0000] gap-2  px-4 py-2  h-70 text-[#fff] rounded shadow "
                                  onClick={() =>
                                    downloadInvoice("No Invoice Found")
                                  }
                                >
                                  Invoice{" "}
                                  <img
                                    className="h-6"
                                    src="/assets/images/Invoice.svg"
                                    alt="Invoice Icon"
                                  />
                                </button>
                              </>
                            )}
                            {e.invoices.length > 0 && (
                              <>
                                <button
                                  className="flex  text-[14px] bg-[#FF0000] gap-2  px-4 py-2  h-70 text-[#fff] rounded shadow "
                                  onClick={() =>
                                    downloadInvoice(e.invoices[0].id)
                                  }
                                >
                                  Invoice{" "}
                                  <img
                                    className="h-6"
                                    src="/assets/images/Invoice.svg"
                                    alt="Invoice Icon"
                                  />
                                </button>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </>
              )}
              {callingFrom === "reservedBookingTab3" && (
                <>
                  {reservationList.map(
                    (e: any, i) =>
                      e.isCancel === true && (
                        <TableRow key={i}>
                          <TableCell
                            className="!text-[14px] text-[#333]"
                            component="th"
                            scope="e"
                          >
                            <ul>
                              <li className="!text-[#333]">
                                {e.clientiByIdCliente.description
                                  .split("/")[0]
                                  .split("-")
                                  .reverse("")
                                  .join(" ") || "No Data"}
                              </li>
                              <li className="text-[#838181] text-[12px]">
                                {e.email || "no data"}
                              </li>
                            </ul>
                          </TableCell>
                          <TableCell className="!text-[14px]">
                            <ul>
                              <li>
                                {e.locationPickupExcepted.description.split(
                                  "-"
                                )[0] || "NO Data"}
                              </li>
                              <li className="text-[#838181] text-[12px] mx-12">
                                {e.startDate.slice(11, 19) || "no data"}
                              </li>
                            </ul>
                          </TableCell>
                          <TableCell className="!text-[14px]">
                            <ul>
                              <li>
                                {e.locationReturnExcepted.description.split(
                                  "-"
                                )[0] || "NO Data"}
                              </li>
                              <li className="text-[#838181] text-[12px] mx-12">
                                {e.endDate.slice(11, 19) || "No Data"}
                              </li>
                            </ul>
                          </TableCell>

                          <TableCell className="!text-[14px]">
                            {`${new Date(e.startDate).getDate()}-0${
                              new Date(e.startDate).getMonth() + 1
                            }-${new Date(e.startDate).getFullYear()}`}{" "}
                            -{" "}
                            {`${new Date(e.endDate).getDate()}-0${
                              new Date(e.endDate).getMonth() + 1
                            }-${new Date(e.endDate).getFullYear()}`}
                          </TableCell>
                          <TableCell className="!text-[14px]">
                            {e.assignedGroup.description || ""}
                          </TableCell>
                          <TableCell>
                            {e.invoices.length == 0 && (
                              <>
                                <button
                                  className="flex  text-[14px] bg-[#FF0000] gap-2  px-4 py-2  h-70 text-[#fff] rounded shadow "
                                  onClick={() =>
                                    downloadInvoice("No Invoice Found")
                                  }
                                >
                                  Invoice{" "}
                                  <img
                                    className="h-6"
                                    src="/assets/images/Invoice.svg"
                                    alt="Invoice Icon"
                                  />
                                </button>
                              </>
                            )}
                            {e.invoices.length > 0 && (
                              <>
                                <button
                                  className="flex  text-[14px] bg-[#FF0000] gap-2  px-4 py-2  h-70 text-[#fff] rounded shadow "
                                  onClick={() =>
                                    downloadInvoice(e.invoices[0].id)
                                  }
                                >
                                  Invoice{" "}
                                  <img
                                    className="h-6"
                                    src="/assets/images/Invoice.svg"
                                    alt="Invoice Icon"
                                  />
                                </button>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
export default memo(ReservationCard);
