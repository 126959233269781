import React, { useState } from "react";
import ProfileNavigation from "../profile/ProfileNavigation";
import { Button, TextField } from "@mui/material";
import { BookingApi } from "../../API/Apis/BookingApi";

export default function ChangePassword() {
  function saveNewPassword() {
    if (changePassword.newPassword == changePassword.confirmPassword) {
      BookingApi.changePassword(changePassword)
        .then((e) => {
          if (e.status == "success") {
            alert("Password Updated successfully");
          }
          if (e.status == "error") {
            alert(e.errorMessage);
          }
          return e.data;
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      alert("The confirmed password does not match the password.");
    }
  }
  const handleOldPasswordChange = (event) => {
    const value = event.target.value;
    setChangePassword((prevState) => ({
      ...prevState,
      oldPassword: value,
    }));
  };

  const handleNewPasswordChange = (event) => {
    const value = event.target.value;
    setChangePassword((prevState) => ({
      ...prevState,
      newPassword: value,
    }));
  };

  const handleConfirmPasswordChange = (event) => {
    const value = event.target.value;
    setChangePassword((prevState) => ({
      ...prevState,
      confirmPassword: value,
    }));
  };

  const [changePassword, setChangePassword] = useState<any>([
    {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  ]);
  return (
    <div>
      <div className="flex mt-12 mr-6 h-480 max-h-screen ">
        <ProfileNavigation />
        <div>
          <div className="container  mx-4">
            <div className="flex flex-col   ">
              <div className="flex justify-between space-x-2 mb-8  ">
                <div>
                  <h1 className="font-medium text-[36px] text-[#232323]">
                    Change Password
                  </h1>
                  <h4 className="text-[#838181] font-normal text-[20px]">
                    You can change your password from here.
                  </h4>
                  <TextField
                    margin="normal"
                    type={"password"}
                    required
                    fullWidth
                    label="Old Password"
                    name="oldPassword"
                    id="oldPassword"
                    onChange={handleOldPasswordChange}
                  />
                  <TextField
                    name="newPassword"
                    type={"password"}
                    id="newPassword"
                    margin="normal"
                    required
                    fullWidth
                    label="New Password"
                    onChange={handleNewPasswordChange}
                  />
                  <TextField
                    name="confirmPassword"
                    id="confirmPassword"
                    type={"password"}
                    margin="normal"
                    required
                    fullWidth
                    label="Confirm Password"
                    onChange={handleConfirmPasswordChange}
                  />
                  <div className="flex gap-4 flex-row-reverse">
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={saveNewPassword}
                      sx={{
                        gap: 3,
                        fontWeight: "bold",
                        bgcolor: "#0202D53",
                        textTransform: "capitalize",
                      }}
                      style={{
                        backgroundColor: "#020D53",
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      type="submit"
                      variant="outlined"
                      sx={{
                        gap: 3,
                        fontWeight: "bold",
                        borderColor: "#000",
                        textTransform: "capitalize",
                      }}
                      style={{ color: "#000" }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
