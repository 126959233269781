import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { BookingApi } from "../../API/Apis/BookingApi";

export default function InvoiceCard() {
  const [invoiceList, setInvoiceList] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);
    const id: any = localStorage.getItem("userID");
    BookingApi.getOnlineUserInvoices(id).then((e) => {
      setInvoiceList(e.data.invoiceList);
      setLoader(false);
    });
  }, []);
  return (
    <>
      {loader ? (
        <Typography className="!text-center  !text-3xl !flex !justify-center  ">
          Loading...
        </Typography>
      ) : (
        <TableContainer>
          <Table
            className="!border-2  rounded-2xl"
            aria-label="simple table"
            stickyHeader
          >
            <TableHead className="!bg-[#020D53] text-[24px] ">
              <TableRow className="!bg-[#020D53] rounded-2xl">
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                  Invoice Number
                </TableCell>
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px] ">
                  Start Date
                </TableCell>
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                  End Date
                </TableCell>
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                  Invoice Amount
                </TableCell>
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                  Status
                </TableCell>
                <TableCell className="!bg-[#020D53] !text-[#fff] !text-[20px]">
                  Download
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceList.map((e: any, i) => (
                <TableRow key={i}>
                  <TableCell
                    className="!text-[14px] text-[#333]"
                    component="th"
                    scope="e"
                  >
                    <ul>
                      <li className="!text-[#333]">
                        {e.prefix + "-" + e.invoiceNumber || ""}
                      </li>
                    </ul>
                  </TableCell>
                  <TableCell
                    className="!text-[14px] text-[#333]"
                    component="th"
                    scope="e"
                  >
                    <ul>
                      <li className="!text-[#333]">{e.startDate || ""}</li>
                    </ul>
                  </TableCell>
                  <TableCell
                    className="!text-[14px] text-[#333]"
                    component="th"
                    scope="e"
                  >
                    <ul>
                      <li className="!text-[#333]">{e.invoiveEndDate || ""}</li>
                    </ul>
                  </TableCell>
                  <TableCell
                    className="!text-[14px] text-[#333]"
                    component="th"
                    scope="e"
                  >
                    <ul>
                      <li className="!text-[#333]">{e.totInvoice || ""}</li>
                    </ul>
                  </TableCell>
                  <TableCell
                    className="!text-[14px] text-[#333]"
                    component="th"
                    scope="e"
                  >
                    <ul>
                      <li
                        className="!text-[#333]"
                        style={{
                          backgroundColor: e.isPaid ? "lightgreen" : "red",
                          textAlign: "center",
                          padding: "10px",
                        }}
                      >
                        {(e.isPaid ? "Paid" : "Unpaid") || ""}
                      </li>
                    </ul>
                  </TableCell>

                  <TableCell>
                    <button className="flex  text-[14px] bg-[#FF0000] gap-2  px-4 py-2  h-70 text-[#fff] rounded shadow ">
                      Invoice{" "}
                      <img
                        className="h-6"
                        src="/assets/images/Invoice.svg"
                        alt="Invoice Icon"
                      />
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
