import {
  BookingVehicleInfoCardProps,
  ChosenOptions,
  VehicleSpecsCardProps,
} from "./BookingVehicleInfoCard.props";
import React, { createContext, useEffect, useState } from "react";

import AppButton from "../../UI/AppButtons/AppButton";
import BookingGetters from "../../../store/onlineBooking/getters";
import { Carousel } from "../Carousel/Carousel";
import Insurance from "../../../API/responses/Insurance.json";
import { InsuranceCardsLayout } from "../Insurance/Insurance";
import { Modal } from "../../UI/AppModal";
import { PageConfigGetters } from "../../../store/PageConfigurations/getters";
import SelectVehicleExtra from "../VehicleExtraSelections/SelectVehicleExtra";
import { TabView } from "../../UI/AppTabView";
import { VehicleSpecification } from "../VehicleSpecification/VehicleSpecification";
import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import theme from "../../../config/MuiColorThemes";
import { SelectedVehicleDetails } from "../../../@types/Booking/SelectedVehicleDetails";
import {
  differenceInCalendarDays,
  differenceInDays,
  format,
  startOfDay,
} from "date-fns";
import BookingApi from "../../../API/Apis/BookingApi";
import { InsuranceDetails } from "../../../@types/DTO/ApiResponses";
import { AppRadioButton } from "../../UI/AppRadioButtons/AppRadioButtons";
import { Vehicle } from "../../../@types/DTO/Vehicles";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { BookingStateRedux } from "../../../store/onlineBooking/states";

function BookingVehicleInfoCard({
  status = "Unavailable",

  ...VehicleDetails
}: BookingVehicleInfoCardProps) {
  const ReservationDetails = BookingGetters.getBookingDetails();
  const chooseVehicleConfig = PageConfigGetters.getChooseVehicleConfig();
  theme.palette["chooseVehiclePayNowButton"].main =
    chooseVehicleConfig.payNowButtonColor;

  theme.palette["addInsuranceButton"].contrastText = theme.palette[
    "addExtrasButton"
  ].contrastText = chooseVehicleConfig.buttonColors;
  const rentalTerms = BookingGetters.getRentalTerms();
  const styles = {
    specificationBorder: "border-2 border-solid border-gray-100",
    textColor: `text-[${chooseVehicleConfig.priceColor}]`,
  };
  const currentReservationDetailsState: BookingStateRedux = useSelector<
    BookingStateRedux,
    BookingStateRedux
  >((e) => e);
  const [hasShownInsuranceTab, setHasShownInsuaranceTab] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("0");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [selectedFields, setSelectedFields] = useState<ChosenOptions>({
    insurance: undefined,
    options: {},
  });

  const [startDate] = useState(
    new Date(
      format(
        new Date(ReservationDetails.pickUpDateAndTime || Date.now()),
        "yyyy-MM-dd"
      )
    )
  );
  const [endDate] = useState(
    new Date(
      format(
        new Date(ReservationDetails.dropOffDateAndTime || Date.now()),
        "yyyy-MM-dd"
      )
    )
  );
  const [numberOfDays] = useState(differenceInCalendarDays(endDate, startDate));
  const [pricePerDay] = useState(
    parseFloat(VehicleDetails.priceDetails.payNow.toString()) /
      (numberOfDays == 0 ? 1 : numberOfDays)
  );
  const [totalPricePerDay, setTotalPricePerDay] = useState(pricePerDay);
  const discount = (100 - VehicleDetails.discount) / 100;
  const [totalPrice, setTotalPrice] = useState<number>(
    parseFloat(VehicleDetails.priceDetails.payNow) * discount
  );
  const [payLaterPrice, setPayLaterPrice] = useState<number>(
    parseFloat(VehicleDetails.priceDetails.payLater) || 0
  );
  const [insuranceList, setInsuranceList] = useState<InsuranceDetails>({
    "Booking Insurance": [],
    Specification: [],
    // isInsuranceAvailable: [],
  });
  const [isInsuranceAvailable, setIsInsuranceAvailable] =
    useState<InsuranceDetails>();
  // boolean | string | number | undefined // hardcoding the insurance set to false for message display
  const [paymentType, setPaymentType] = useState<
    "PAYNOW" | "PAYLATER" | "BOOKNOW" | undefined
  >();
  const AppSettings = BookingGetters.getAppSettings();
  const [showButton, setShowButton] = useState<boolean>(
    !!AppSettings?.showOnePayMethodTwoPrice
  );
  const bookingVehicleDetails = BookingGetters.getBookingDetails();
  const payNowDisAmount =
    (parseFloat(VehicleDetails.priceDetails.payLater.toString()) *
      VehicleDetails.discount) /
    100;
  useEffect(() => {
    setHasShownInsuaranceTab(hasShownInsuranceTab || activeTab == "1");
  }, [activeTab]);
  // useEffect(() => {
  //   // setShowButton(true);
  //   BookingApi.getAppSetting().then((e) => {
  //     setShowButton(e.showOnePayMethodTwoPrice);
  //   });
  // }, []);

  // Centralized API call for insurance api ----- now replaced with individual call on every button
  // useEffect(() => {
  //   BookingApi.getInsuranceDetails(
  //     ReservationDetails,
  //     VehicleDetails.vehicleGroup,
  //     VehicleDetails.extras
  //   ).then((e) => {
  //     setInsuranceList(e);
  //     setIsInsuranceAvailable(e);
  //   });
  // }, []);

  useEffect(() => {
    let price: number = parseFloat(
      selectedFields.insurance?.price?.toString() || "0"
    );
    //*numberOfDays; // enable if myrent doesnot sends the insurance on the basis of per day

    if (selectedFields.options) {
      Object.values(selectedFields.options).map((el) => {
        price += el.price * el.quantity;
      });
    }
    const totalPrice =
      parseFloat(VehicleDetails.priceDetails.payNow) * discount;
    const totalPriceAfterExtra = price + totalPrice;
    setTotalPricePerDay(
      totalPriceAfterExtra / (numberOfDays == 0 ? 1 : numberOfDays)
    );
    setTotalPrice(totalPriceAfterExtra);
    setPayLaterPrice(parseFloat(VehicleDetails.priceDetails.payLater) + price);
  }, [selectedFields]);
  function PayLaterButton() {
    return (
      <AppButton
        disabled={status.toLowerCase() === "unavailable"}
        style={{ borderWidth: "0.1rem" }}
        variant="outlined"
        color="addInsuranceButton"
        className="w-fit min-h-12 "
        clickHandler={() => {
          BookingApi.getInsuranceDetails(
            ReservationDetails,
            VehicleDetails.vehicleGroup,
            VehicleDetails.extras
          ).then((e) => {
            setInsuranceList(e);
            setIsInsuranceAvailable(e);
          });
          setPaymentType("PAYLATER");
          if (!hasShownInsuranceTab) {
            setActiveTab("1");
            setShowModal(true);
            scrollToTop();
          } else {
            VehicleDetails.moveToPayment({
              isDiscounted: 0,
              // payNowDis: 0,
              type: "PAYLATER",
              price: payLaterPrice,
              addedExtraOptions: selectedFields.options,
              selectedInsurance: selectedFields.insurance,
            });
          }
        }}
      >
        {/* {VehicleDetails.discount}
        {VehicleDetails.priceDetails.payNow} */}
        <p
          className={
            "min-w-32 w-full h-full flex items-center justify-center md:text-xl text-xs capitalize text-[#707070]"
          }
        >
          {status.toLowerCase() == "onrequest"
            ? t("Pay Later *")
            : t("Pay Later")}
        </p>
      </AppButton>
    );
  }

  function PayNowButton() {
    return (
      <AppButton
        disabled={status.toLowerCase() === "unavailable"}
        color="bookingButtonColor"
        className="w-full min-h-12"
        clickHandler={() => {
          BookingApi.getInsuranceDetails(
            ReservationDetails,
            VehicleDetails.vehicleGroup,
            VehicleDetails.extras
          ).then((e) => {
            setInsuranceList(e);
            setIsInsuranceAvailable(e);
          });
          // console.log(totalPrice);
          setPaymentType("PAYNOW");
          if (!hasShownInsuranceTab) {
            setActiveTab("1");
            setShowModal(true);
            scrollToTop();
            return;
          }
          VehicleDetails.moveToPayment({
            isDiscounted: payNowDisAmount,
            // parseFloat(VehicleDetails.priceDetails.payLater) !== totalPrice,
            // payNowDis: payNowDisAmount,
            type: "PAYNOW",
            price: totalPrice,
            addedExtraOptions: selectedFields.options,
            selectedInsurance: selectedFields.insurance,
          });
        }}
      >
        <p className="min-w-32 w-full h-full flex items-center justify-center  text-white md:text-xl text-xs capitalize  ">
          {status == "OnRequest" ? t("Pay Now *") : t("Pay Now")}
        </p>
      </AppButton>
    );
  }

  function BookButton() {
    return (
      <AppButton
        disabled={status.toLowerCase() === "unavailable"}
        color="bookingButtonColor"
        className="w-full min-h-12 rounded-md"
        clickHandler={() => {
          BookingApi.getInsuranceDetails(
            ReservationDetails,
            VehicleDetails.vehicleGroup,
            VehicleDetails.extras
          ).then((e) => {
            setInsuranceList(e);
            setIsInsuranceAvailable(e);
          });
          // console.log(totalPrice);
          setPaymentType("BOOKNOW");
          if (!hasShownInsuranceTab) {
            setActiveTab("1");
            setShowModal(true);
            return;
          }
          VehicleDetails.moveToPayment({
            isDiscounted: payNowDisAmount,
            // parseFloat(VehicleDetails.priceDetails.payLater) !== totalPrice,
            // payNowDis: payNowDisAmount,
            type: paymentType as "BOOKNOW" | "PAYLATER",
            price: totalPrice,
            addedExtraOptions: selectedFields.options,
            selectedInsurance: selectedFields.insurance,
          });
        }}
      >
        <p className="min-w-32 w-full h-full flex items-center justify-center  text-white md:text-xl text-xs capitalize  ">
          {t("Book Now")}
        </p>
      </AppButton>
    );
  }
  function AddExtrasButton() {
    return (
      <AppButton
        disabled={status.toLowerCase() === "unavailable"}
        color="addExtrasButton"
        className="w-40 pb-2 md:pb-0"
        clickHandler={() => {
          setActiveTab("0");
          setShowModal(true);
          scrollToTop();
          BookingApi.getInsuranceDetails(
            ReservationDetails,
            VehicleDetails.vehicleGroup,
            VehicleDetails.extras
          ).then((e) => {
            setInsuranceList(e);
            setIsInsuranceAvailable(e);
          });
        }}
      >
        <p
          className={
            "w-full h-full flex items-center justify-center md:text-md text-xs"
          }
        >
          {t("Add Extras")}
        </p>
      </AppButton>
    );
  }
  // const scrollToTop = () => {
  // window.scrollTo(0, 0);
  // };

  const scrollToTop = () => {
    window.parent.postMessage(
      {
        event_id: "scrollToTop",
      },
      "*"
    );
  };
  function AddInsuranceButton() {
    return (
      <AppButton
        disabled={status.toLowerCase() === "unavailable"}
        color="addInsuranceButton"
        className="w-40"
        clickHandler={() => {
          BookingApi.getInsuranceDetails(
            ReservationDetails,
            VehicleDetails.vehicleGroup,
            VehicleDetails.extras
          ).then((e) => {
            setInsuranceList(e);
            setIsInsuranceAvailable(e);
          });
          setActiveTab("1");
          setShowModal(true);
          scrollToTop();
        }}
      >
        <p
          className={
            "w-full h-full flex items-center justify-center md:text-md text-xs font-montserrat"
          }
        >
          {t("Add Protections")}
        </p>
      </AppButton>
    );
  }
  return (
    <section className="hover:drop-shadow-lg cursor-pointer  flex flex-col bg-white  box-border w-full h-full font-roboto text-[#004188]">
      <header
        className={"text-black w-full  flex md:flex-row flex-col p-2 px-4"}
      >
        <aside className={"flex flex-row"}>
          <div className={" w-24"}>
            <div className={"sm:w-full h-48"}>
              <Carousel
                carouselItems={VehicleDetails.vehicleImages.map(
                  (arrEl, index) => {
                    return (
                      <img
                        alt={""}
                        src={arrEl}
                        key={index}
                        className={"w-full h-full object-contain"}
                      />
                    );
                  }
                )}
                onSlideItemClick={(index: number) => {
                  setSelectedImage(index);
                  // console.log(index);
                }}
              />
            </div>
          </div>
          <div className="w-full h-full p-4 flex items-center justify-center">
            {selectedImage >= 0 && (
              <img
                src={VehicleDetails.vehicleImages[selectedImage]}
                className={
                  "md:min-w-80 w-full aspect-video object-contain h-48"
                }
                alt={""}
              />
            )}
          </div>
        </aside>
        <main className="w-full">
          <header className="flex flex-col">
            <h2 className="font-bold box-border py-4 md:text-3xl text-2xl">
              {VehicleDetails.vehicleName.split(" or ")[0]}
            </h2>
          </header>
          <footer className={"flex md:flex-row flex-col flex-wrap  gap-8 grow"}>
            <div className="flex flex-row flex-wrap w-fit md:gap-8 gap-0">
              <div className="flex flex-col flex-wrap w-fit gap-4">
                {VehicleDetails.specifications.slice(0, 3).map((el, index) => {
                  return (
                    <div key={index} className="h-fit w-fit">
                      <VehicleSpecsCard {...el} />
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col flex-wrap w-fit gap-4">
                {VehicleDetails.specifications.slice(4, 6).map((el, index) => {
                  return (
                    <div key={index} className="h-fit w-fit text-sm">
                      <VehicleSpecsCard {...el} />
                    </div>
                  );
                })}
                {VehicleDetails.specifications.length > 6 && (
                  <span
                    className="text-blue-800 underline"
                    onClick={() => {
                      setActiveTab("3");
                      setShowModal(true);
                    }}
                  >
                    {t("more specs")}
                  </span>
                )}
              </div>
            </div>
            <main
              className={"grow font-bold box-border flex justify-end gap-8"}
            >
              <div className={"md:hidden flex flex-col grow justify-end "}>
                {/* <div className="w-fit"> */}
                <AddExtrasButton />
                {/* </div> */}
                <AddInsuranceButton />
              </div>
              <div className={"flex flex-col gap-8 justify-end box-border"}>
                {(() => {
                  const amountDetails = formatPriceWithCurrencySymbol(
                    BookingGetters.getCurrency(),
                    totalPricePerDay
                  );
                  return (
                    <span className="main-price  text-inherit md:text-4xl text-md">
                      <p className={"whitespace-nowrap " + styles.textColor}>
                        {amountDetails.symbol + " " + amountDetails.price}
                        <sub className="font-normal h-full">/{t("day")}</sub>
                      </p>
                    </span>
                  );
                })()}
                {(() => {
                  const amountDetails = formatPriceWithCurrencySymbol(
                    BookingGetters.getCurrency(),
                    totalPrice
                  );
                  return (
                    <span className="total-price md:text-xl text-sm text-gray-500  whitespace-nowrap">
                      <span>{t("Total")}: </span>
                      <span>{amountDetails.symbol + " "}</span>
                      <span>{amountDetails.price}</span>
                    </span>
                  );
                })()}
                <div className="flex justify-end">
                  {showButton ? <PayNowButton /> : <BookButton />}
                </div>
              </div>
            </main>
          </footer>
        </main>
      </header>
      <div className="w-full border-solid border-2"></div>
      <footer className="flex flex-wrap gap-3 box-border p-4 items-center">
        <div className={"md:flex flex-wrap gap-2 hidden"}>
          {/* <div className="w-fit pb-2"> */}
          <AddExtrasButton />
          {/* </div> */}
          <AddInsuranceButton />
        </div>
        <div className="text-[#7a7c80] text-right font-medium">
          {status == "OnRequest"
            ? t("Vehicle with limited availability - subjected to confirmation")
            : ""}
        </div>
        <div className="grow flex  gap-3 justify-end md:order-none order-first">
          <div>
            {(() => {
              const amountDetails = formatPriceWithCurrencySymbol(
                BookingGetters.getCurrency(),
                payLaterPrice
              );
              return (
                <span
                  className={
                    "total-price text-gray-500 flex items-center justify-center h-full w-full " +
                    styles.textColor +
                    " md:text-4xl text-xl "
                  }
                >
                  {amountDetails.symbol + " "}
                  {amountDetails.price}
                </span>
              );
            })()}
          </div>
          {showButton ? <PayLaterButton /> : ""}
        </div>
      </footer>
      {showModal && (
        <Modal
          // className="modal"
          isOpen={true}
          handleClose={() => {
            setShowModal(() => false);
          }}
        >
          <div className="h-full w-full">
            {/*<div className={"overflow-auto"}>*/}
            <div>
              <div>
                <TabView
                  handleChange={(e, tabIndex) => {
                    setActiveTab(tabIndex);
                  }}
                  activeTabValue={activeTab}
                  tabs={[
                    {
                      title: t("Add Extras"),
                      // title: "Extra Options",
                      content: (
                        <div className={"h-full"}>
                          <SelectVehicleExtra
                            values={selectedFields.options || {}}
                            onChange={(e: any) => {
                              setSelectedFields((prev) => {
                                return {
                                  ...prev,
                                  // abc: 1, implement the logic for automatically adding age related extras
                                  options: e,
                                };
                              });
                            }}
                            options={VehicleDetails.extras}
                          />
                        </div>
                      ),
                    },
                    {
                      // title: "Image Gallery",
                      title: t("Add Protections"),
                      content:
                        // <>
                        //   <AppRadioButton
                        //     options={insuranceList.Specification.map((e) => {
                        //       return e["Booking Insurance"] || e["Description"];
                        //     })}
                        //     label="Choose Insurance"
                        //     isInRow={false}
                        //     value={selectedFields.insurance?.details || ""}
                        //     onChange={(e) => {
                        //       setSelectedFields((prev) => {
                        //         prev.insurance = {
                        //           details: e,
                        //           price: parseFloat(
                        //             parseFloat(
                        //               //Insurance[e].pricePerDayInUSD
                        //               "0"
                        //             ).toFixed(2)
                        //           ),
                        //         };

                        //         return {
                        //           ...prev,
                        //         };
                        //       });
                        //     }}
                        //   />
                        // </>
                        isInsuranceAvailable?.Specification?.[0] ? (
                          <InsuranceCardsLayout
                            selectedInsuranceIndex={insuranceList[
                              "Booking Insurance"
                            ].findIndex(
                              (e) =>
                                e.Description.toLowerCase() ===
                                selectedFields.insurance?.details.toLowerCase()
                            )}
                            onSelected={(e: number) => {
                              const index = e;
                              setSelectedFields((prev) => {
                                if (index < 0) {
                                  delete prev.insurance;
                                } else {
                                  prev.insurance = {
                                    details:
                                      insuranceList["Booking Insurance"][index]
                                        .Description,
                                    insuranceId:
                                      insuranceList["Booking Insurance"][
                                        index
                                      ].Id.toString(),
                                    price: parseFloat(
                                      parseFloat(
                                        insuranceList["Booking Insurance"][
                                          index
                                        ].Amount.toString()
                                      ).toFixed(2)
                                    ),
                                  };
                                }
                                return {
                                  ...prev,
                                };
                              });
                            }}
                            insurances={
                              insuranceList["Booking Insurance"]?.map((e) => {
                                return {
                                  name: e.Description,
                                  pricePerDayInUSD: e.Amount.toString(),
                                  features: e.Specification,
                                  isRecommended: e.isRecommended,
                                  bookingText: e.bookingText,
                                };
                              }) || []
                            }
                          />
                        ) : (
                          <p className="font-semibold text-black text-align-center">
                            No Insurance Available
                          </p>
                        ),
                    },
                    {
                      // title: "Rental Terms",
                      title: t("Rental Terms"),
                      content: (
                        /*<p className="h-full overflow-auto text-justify box-border px-4">
                          {rentalTerms}
                        </p>
  */
                        <div
                          style={{ whiteSpace: "pre-line" }}
                          dangerouslySetInnerHTML={{ __html: rentalTerms }}
                        />
                      ),
                    },
                    {
                      // title: "All Specifications",
                      title: t("All Specifications"),
                      content: (
                        <div className="flex flex-row flex-wrap gap-2 h-full overflow-auto">
                          {VehicleDetails.specifications.map((el, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  "w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 flex flex-col items-center justify-center gap-4" +
                                  " " +
                                  styles.specificationBorder
                                }
                                // style={{ flexBasis: "calc(33.33% - 8px)" }} // Adjust the flex basis to create a 3-column layout
                              >
                                <VehicleSpecification {...el} />
                              </div>
                            );
                          })}
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
              <div
                style={{
                  marginTop: "15px",
                  textAlign: "-webkit-center",
                  // position: "sticky",
                  // bottom: "0",
                  // zIndex: "1",
                }}
              >
                <AppButton
                  color={"bookingButtonColor"}
                  className={"box-border py-4 w-fit text-[#ffffff]"}
                  clickHandler={() => {
                    BookingApi.getInsuranceDetails(
                      ReservationDetails,
                      VehicleDetails.vehicleGroup,
                      VehicleDetails.extras
                    ).then((e) => {
                      setInsuranceList(e);
                      setIsInsuranceAvailable(e);
                    });
                    // if (paymentType.toLowerCase() === "paynow") {
                    //   isDiscounted = payNowDisAmount;
                    //   // parseFloat(VehicleDetails.priceDetails.payLater) !==
                    //   // totalPrice;
                    // }

                    if (!paymentType) {
                      setShowModal(false);
                    } else {
                      if (paymentType.toLowerCase() === "paylater") {
                        VehicleDetails.moveToPayment({
                          isDiscounted: 0,
                          type: "PAYLATER",
                          price: payLaterPrice,
                          addedExtraOptions: selectedFields.options,
                          selectedInsurance: selectedFields.insurance,
                        } as SelectedVehicleDetails);
                        return;
                      } else if (paymentType.toLowerCase() === "paynow") {
                        VehicleDetails.moveToPayment({
                          isDiscounted: payNowDisAmount,
                          type: "PAYNOW",
                          price: totalPrice,
                          addedExtraOptions: selectedFields.options,
                          selectedInsurance: selectedFields.insurance,
                        } as SelectedVehicleDetails);
                      } else {
                        VehicleDetails.moveToPayment({
                          isDiscounted: payNowDisAmount,
                          type: "BOOKNOW",
                          price: totalPrice,
                          addedExtraOptions: selectedFields.options,
                          selectedInsurance: selectedFields.insurance,
                        } as SelectedVehicleDetails);
                      }
                    }
                  }}
                >
                  <p className="text-[#ffffff]"> {t("Proceed")}</p>
                </AppButton>
              </div>
            </div>
            {/*<div*/}
            {/*  className={*/}
            {/*    "flex flex-col items-center justify-center modal-content"*/}
            {/*  }*/}
            {/*>*/}
            {/*  <AppButton*/}
            {/*    color={"bookingButtonColor"}*/}
            {/*    className={"box-border py-4 w-fit text-[#ffffff]"}*/}
            {/*    clickHandler={() => {*/}
            {/*      BookingApi.getInsuranceDetails(*/}
            {/*        ReservationDetails,*/}
            {/*        VehicleDetails.vehicleGroup,*/}
            {/*        VehicleDetails.extras*/}
            {/*      ).then((e) => {*/}
            {/*        setInsuranceList(e);*/}
            {/*        setIsInsuranceAvailable(e);*/}
            {/*      });*/}
            {/*      // if (paymentType.toLowerCase() === "paynow") {*/}
            {/*      //   isDiscounted = payNowDisAmount;*/}
            {/*      //   // parseFloat(VehicleDetails.priceDetails.payLater) !==*/}
            {/*      //   // totalPrice;*/}
            {/*      // }*/}

            {/*      if (!paymentType) {*/}
            {/*        setShowModal(false);*/}
            {/*      } else {*/}
            {/*        if (paymentType.toLowerCase() === "paylater") {*/}
            {/*          VehicleDetails.moveToPayment({*/}
            {/*            isDiscounted: 0,*/}
            {/*            type: "PAYLATER",*/}
            {/*            price: payLaterPrice,*/}
            {/*            addedExtraOptions: selectedFields.options,*/}
            {/*            selectedInsurance: selectedFields.insurance,*/}
            {/*          } as SelectedVehicleDetails);*/}
            {/*          return;*/}
            {/*        } else if (paymentType.toLowerCase() === "paynow") {*/}
            {/*          VehicleDetails.moveToPayment({*/}
            {/*            isDiscounted: payNowDisAmount,*/}
            {/*            type: "PAYNOW",*/}
            {/*            price: totalPrice,*/}
            {/*            addedExtraOptions: selectedFields.options,*/}
            {/*            selectedInsurance: selectedFields.insurance,*/}
            {/*          } as SelectedVehicleDetails);*/}
            {/*        } else {*/}
            {/*          VehicleDetails.moveToPayment({*/}
            {/*            isDiscounted: payNowDisAmount,*/}
            {/*            type: "BOOKNOW",*/}
            {/*            price: totalPrice,*/}
            {/*            addedExtraOptions: selectedFields.options,*/}
            {/*            selectedInsurance: selectedFields.insurance,*/}
            {/*          } as SelectedVehicleDetails);*/}
            {/*        }*/}
            {/*      }*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <p className="text-[#ffffff]"> {t("Proceed")}</p>*/}
            {/*  </AppButton>*/}
            {/*</div>*/}
          </div>
        </Modal>
      )}
    </section>
  );
}

function VehicleSpecsCard({ iconUrl, value }: VehicleSpecsCardProps) {
  return (
    <div className="w-full min-w-fit flex flex-row gap-2">
      <div className="flex items-center">
        <img className="h-6 w-6" src={iconUrl} alt={""} />
      </div>
      <span className="h-fit flex items-center md:text-lg text-md">
        {value}
      </span>
    </div>
  );
}
export { BookingVehicleInfoCard };
